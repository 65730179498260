.address{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.address li{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.address li p{
    font-family: 'Poppins',sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #FEFEFE;
}
@media only screen and (max-width: 860px){
    .address{
        display: flex;
        flex-direction: row;
        gap: 40px;
    }
}
@media only screen and (max-width: 646px){
    .address{
        flex-direction: column;

    }
    .address li{
        justify-content: center;
    }
}