
.main-menu ul{
    display: flex;
    flex-direction: row;
    gap: 48px;
    width: fit-content;
    margin: 0 auto;
}
.main-menu ul li{
    cursor: pointer;
    padding: 18px 64px;
    background-color: rgba(254, 254, 254, 0.2);
    border-radius: 100px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #fefefe;
    font-family: 'Poppins', sans-serif;
    border: 2px solid rgba(254, 254, 254, 0.005);
}
.main-menu ul li:hover{
    border: solid 2px #FFBA03;
    background-color: rgba(254, 254, 254, 0);
}
.main-active{
    border: solid 2px #FFBA03 !important;
    background-color: rgba(254, 254, 254, 0) !important;
}
.MenuTables{
    flex-grow: 1;
    width: fit-content;
    padding: 0px 128px 0px 128px;
}
.menu-table{
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    margin: 0px auto 64px auto;
    gap: 48px !important;
    padding: 128px 128px 0px 128px;
    justify-content: space-between;
}
.steak{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto !important;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    /* padding: 32px; */
    margin-top: 116px;
    z-index: -1;
}
.steak img{
    width: 180px;
    position: absolute;
    top: -64px;
    object-fit:cover !important;
    left: auto;
}
.steak0 img,.steak1 img{
    width: 204px;
}
.steak-information{
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-color: rgba(254, 254, 254, 0.4);
    padding: 124px 32px 63px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.steak-information h2{
    font-family: 'Poppins', sans-serif;
    color: #fefefe;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    text-align: center;
}
.steak-information hr{
    width: 100%;
    height: 2px;
    background-color: #FFBA03;
    border: none;
    margin-top: 8px;
    margin-bottom: 16px;
    border-radius: 10px;
}
.review,.price{
    margin: 0px auto;
    text-align: center;
    padding: 0px 20px; 
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #fefefe;
    line-height: 26px;
    width: 240px;
}
.price{
    margin-top: 48px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 48px;
}
/*  */
.content {  
  display: none;
}
.active-content {
  display: block;
}
@media only screen and (max-width: 1234px) {
    .menu-table{
        padding: 10px ;
    }
}
@media only screen and (max-width: 1012px) {
    .steak-information{
        padding: 124px 10px 63px 10px;
    }
}
@media only screen and (max-width: 940px) {
    .main-menu ul{
        gap: 20px;
    }
    .main-menu ul li{
        padding: 16px 40px;
    }
    
}
@media only screen and (max-width: 926px) {
    .steak{
        position: none !important;
    }
    .steak img{
        /* position: absolute !important; */
        top: 0px !important;
    }
    
    .steak-information{
        display: none !important;
        padding: 24px !important;
        gap: 8px !important;
    }
    .steak img{
        position: relative ;
        width: 180px;
    }
    .steak:hover .steak-information{
        display: flex !important;
        width: 180px !important;
        height: 180px !important;
        position: absolute !important;
    }
    .steak:hover .steak-information hr{
        display: none;
    }
    .steak:hover .steak-information h2,.review,.price{
        color: black;
        padding: 0px;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .main-menu ul{
        gap: 20px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .main-menu ul li{
        padding: 8px 24px;
    }
    .MenuTables{
        padding: 16px !important;

    }
    .menu-table{
        gap: 0px !important;
        justify-content: space-between;
    }
}