
.footer-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
}
@media only screen and (max-width: 860px){
    .footer-wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 48px;
        align-items: center;
        padding-top: 40px;
    }
}