
.links,.Socials{
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    padding-bottom: 42px;
    border-bottom: solid 1px white;
}
.company{
    width: fit-content !important;
    text-align: left !important;
    padding: 0px !important;
    margin: 0px !important;
    color: aliceblue;
}
.Socials{
    border: none;
    gap: 24px;
    padding: 0px;
}
.instagram,.facebook{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-image: url(../../img/Group\ 8.png);
    background-position: center;
    background-repeat: no-repeat;
}
.facebook{
    background-image: url(../../img/Group\ 9.png);
}