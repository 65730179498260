
.About-wrap{
    width: fit-content;
    padding: 104px auto 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 64px;
    margin: 0px auto;
}
.photos{
    display: flex;
    flex-direction: row;
    gap: 16px;
}
.column-photo{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.About-txt{
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    color: #fefefe;
    width: 512px;
}
@media only screen and (max-width: 1234px) {
    .About-wrap{
        flex-direction: column;
    }
}
@media only screen and (max-width:560px){
    .photos{
        gap: 16px;
        width: 400px;
    }
    .photos img{
        width: 150px;
    }
    .column-photo{
        /* ga */
    }
    .column-photo img{
        width: 100%;
    }
    .About-txt{
        width: 400px;
        text-align: center;
    }

}