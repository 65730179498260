
.contact ul li{
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
.contact p{
    color: #FEFEFE;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}
@media only screen and (max-width: 860px){
    .contact ul{
        display: flex;
        gap: 40px;
    }
}
@media only screen and (max-width: 548px){
    .contact ul{
        flex-direction: column;
    }
    .contact ul li{
        justify-content: center;
    }
}