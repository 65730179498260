*{
    margin: 0px;
    padding: 0px;
}
.Main{
    width: 100%;
    height: max-content;
    margin-top: -4px;
    padding-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 88px;
}
.Main h1{
    font-size: 40px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    color: #FFBA03;
    width: fit-content;
    margin: 0 auto;
    text-transform: uppercase;
}

@media only screen and (min-width: 1250px) {
 .Main{
    background-size: cover !important;
 }   
}