h1{
    text-transform: uppercase;
    width: fit-content;
    font-family: 'Poppins', sans-serif;
    color: #FFBA03;
    font-size: 40px;
    line-height: 50px;
    font-weight: 900;
    margin: 48px auto;
}
#AboutUs{
    background-color: #1B1B1B !important;
    padding-top: 80px;
    padding-bottom: 80px;
    /* z-index: 999; */
}